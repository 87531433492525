/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionUpdateRequestQuestionTranslationsValue } from './QuestionUpdateRequestQuestionTranslationsValue';
import {
  QuestionUpdateRequestQuestionTranslationsValueFromJSON,
  QuestionUpdateRequestQuestionTranslationsValueFromJSONTyped,
  QuestionUpdateRequestQuestionTranslationsValueToJSON
} from './QuestionUpdateRequestQuestionTranslationsValue';

/**
 *
 * @export
 * @interface QuestionUpdateRequest
 */
export interface QuestionUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequest
   */
  level: QuestionUpdateRequestLevelEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequest
   */
  answer_type: QuestionUpdateRequestAnswerTypeEnum;
  /**
   * Question Translations
   * @type {{ [key: string]: QuestionUpdateRequestQuestionTranslationsValue; }}
   * @memberof QuestionUpdateRequest
   */
  question_translations?: { [key: string]: QuestionUpdateRequestQuestionTranslationsValue };
  /**
   *
   * @type {string}
   * @memberof QuestionUpdateRequest
   */
  status: QuestionUpdateRequestStatusEnum;
}

/**
 * @export
 */
export const QuestionUpdateRequestLevelEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type QuestionUpdateRequestLevelEnum =
  (typeof QuestionUpdateRequestLevelEnum)[keyof typeof QuestionUpdateRequestLevelEnum];

/**
 * @export
 */
export const QuestionUpdateRequestAnswerTypeEnum = {
  Text: 'text',
  Select: 'select'
} as const;
export type QuestionUpdateRequestAnswerTypeEnum =
  (typeof QuestionUpdateRequestAnswerTypeEnum)[keyof typeof QuestionUpdateRequestAnswerTypeEnum];

/**
 * @export
 */
export const QuestionUpdateRequestStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type QuestionUpdateRequestStatusEnum =
  (typeof QuestionUpdateRequestStatusEnum)[keyof typeof QuestionUpdateRequestStatusEnum];

/**
 * Check if a given object implements the QuestionUpdateRequest interface.
 */
export function instanceOfQuestionUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'level' in value;
  isInstance = isInstance && 'answer_type' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function QuestionUpdateRequestFromJSON(json: any): QuestionUpdateRequest {
  return QuestionUpdateRequestFromJSONTyped(json, false);
}

export function QuestionUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    name: json['name'],
    level: json['level'],
    answer_type: json['answer_type'],
    question_translations: !exists(json, 'question_translations')
      ? undefined
      : mapValues(
          json['question_translations'],
          QuestionUpdateRequestQuestionTranslationsValueFromJSON
        ),
    status: json['status']
  };
}

export function QuestionUpdateRequestToJSON(value?: QuestionUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    name: value.name,
    level: value.level,
    answer_type: value.answer_type,
    question_translations:
      value.question_translations === undefined
        ? undefined
        : mapValues(
            value.question_translations,
            QuestionUpdateRequestQuestionTranslationsValueToJSON
          ),
    status: value.status
  };
}
