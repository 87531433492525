/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
 */
export interface ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner {
  /**
   * product option channel configuration id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  product_option_channel_configuration_id?: string;
  /**
   * product option id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  product_option_id: string;
  /**
   * channel id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  channel_id: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  channel_connection_status: ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  channel_commission_rate?: number;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  reservable_before: string | null;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner
   */
  reservable_after: string | null;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum =
  {
    Active: 'active',
    Inactive: 'inactive',
    Deleted: 'deleted'
  } as const;
export type ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum =
  (typeof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum)[keyof typeof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'channel_id' in value;
  isInstance = isInstance && 'channel_connection_status' in value;
  isInstance = isInstance && 'reservable_before' in value;
  isInstance = isInstance && 'reservable_after' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner {
  return ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_option_channel_configuration_id: !exists(
      json,
      'product_option_channel_configuration_id'
    )
      ? undefined
      : json['product_option_channel_configuration_id'],
    product_option_id: json['product_option_id'],
    channel_id: json['channel_id'],
    channel_connection_status: json['channel_connection_status'],
    channel_commission_rate: !exists(json, 'channel_commission_rate')
      ? undefined
      : json['channel_commission_rate'],
    reservable_before: json['reservable_before'],
    reservable_after: json['reservable_after']
  };
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerToJSON(
  value?: ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_option_channel_configuration_id: value.product_option_channel_configuration_id,
    product_option_id: value.product_option_id,
    channel_id: value.channel_id,
    channel_connection_status: value.channel_connection_status,
    channel_commission_rate: value.channel_commission_rate,
    reservable_before: value.reservable_before,
    reservable_after: value.reservable_after
  };
}
