import { useToastService } from '@/utils/toast';
import i18n from '@/i18n';
import type { TicketingResponsePayload } from '@openapi/models';
import { FetchError, ResponseError } from '@openapi/runtime';
import type { Ref } from 'vue';

export const useUserFeedback = () => {
  const toast = useToastService();

  type TicketingResponseSuccessPayload<T> = T & { success: true };
  type TicketingResponseFailedPayload = {
    success: false;
    exception_code?: string;
    exception_message?: string;
  };

  async function awaitPromiseWithErrorHandling<T>(
    fn: Promise<T & TicketingResponsePayload>,
    notifyUserAboutSuccessfulResponse = false,
    loadingRef?: Ref<boolean>
  ): Promise<TicketingResponseSuccessPayload<T> | TicketingResponseFailedPayload> {
    const NOTIFICATION_DURATION = 4 * 1000;
    try {
      if (loadingRef) {
        loadingRef.value = true;
      }
      // Execute the promise
      const response = await fn;

      if (!response.success) {
        // If response.success is false, extract exception from the response
        // and display a user-friendly message
        const userFriendlyExceptionMessage = generateUserFriendlyExceptionMessage<T>(response);

        // Show a notification to the user with translated exception and an error code
        toast.add({
          severity: 'error',
          summary: i18n.global.t('portal_error_title'),
          detail: userFriendlyExceptionMessage,
          life: NOTIFICATION_DURATION
        });
      } else if (notifyUserAboutSuccessfulResponse) {
        // Display notification about successfully completing the operation
        toast.add({
          severity: 'success',
          summary: i18n.global.t('portal_success_title'),
          detail: i18n.global.t('portal_success_text'),
          life: NOTIFICATION_DURATION
        });
      }

      return response;
    } catch (err) {
      if ((err instanceof DOMException && err.name === 'AbortError') || err instanceof FetchError) {
        // The request was aborted by the postMiddleware (PARTNER_NOT_FOUND ExpectedException); this situation also
        // leads to FetchErrors
        throw err;
      }
      console.log(err);
      let userFriendlyExceptionMessage = i18n.global.t('portal_error_text');
      if (err instanceof ResponseError) {
        const body = await err.response.json();
        userFriendlyExceptionMessage = generateUserFriendlyExceptionMessage<T>(body);
      }
      toast.add({
        severity: 'error',
        summary: i18n.global.t('portal_error_title'),
        detail: userFriendlyExceptionMessage,
        life: NOTIFICATION_DURATION
      });
      throw err;
    } finally {
      if (loadingRef) {
        loadingRef.value = false;
      }
    }
  }

  async function provideUserFeedbackForClipboard<T>(fn: Promise<T>): Promise<T> {
    try {
      const result = await fn;
      toast.add({
        severity: 'info',
        summary: i18n.global.t('portal_info_title'),
        detail: i18n.global.t('portal_info_text'),
        life: 10000
      });
      return result;
    } catch (err) {
      toast.add({
        severity: 'error',
        summary: i18n.global.t('portal_error_title'),
        detail: i18n.global.t('portal_error_text'),
        life: 10000
      });
      throw err;
    }
  }

  return {
    awaitPromiseWithErrorHandling,
    provideUserFeedbackForClipboard
  };
};

function generateUserFriendlyExceptionMessage<T>(response: T & TicketingResponsePayload) {
  if (response.exception_code && response.exception_message) {
    return `${response.exception_message} (${response.exception_code})`;
  }
  return i18n.global.t('portal_error_text');
}
