/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { QuestionByIdReadResponseQuestionTranslationsInner } from './QuestionByIdReadResponseQuestionTranslationsInner';
import {
  QuestionByIdReadResponseQuestionTranslationsInnerFromJSON,
  QuestionByIdReadResponseQuestionTranslationsInnerFromJSONTyped,
  QuestionByIdReadResponseQuestionTranslationsInnerToJSON
} from './QuestionByIdReadResponseQuestionTranslationsInner';

/**
 *
 * @export
 * @interface QuestionByIdReadResponseQuestion
 */
export interface QuestionByIdReadResponseQuestion {
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  level: QuestionByIdReadResponseQuestionLevelEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  answer_type: QuestionByIdReadResponseQuestionAnswerTypeEnum;
  /**
   *
   * @type {string}
   * @memberof QuestionByIdReadResponseQuestion
   */
  status: QuestionByIdReadResponseQuestionStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof QuestionByIdReadResponseQuestion
   */
  created_at: Date;
  /**
   *
   * @type {Array<QuestionByIdReadResponseQuestionTranslationsInner>}
   * @memberof QuestionByIdReadResponseQuestion
   */
  translations: Array<QuestionByIdReadResponseQuestionTranslationsInner>;
}

/**
 * @export
 */
export const QuestionByIdReadResponseQuestionLevelEnum = {
  Booking: 'booking',
  Participant: 'participant'
} as const;
export type QuestionByIdReadResponseQuestionLevelEnum =
  (typeof QuestionByIdReadResponseQuestionLevelEnum)[keyof typeof QuestionByIdReadResponseQuestionLevelEnum];

/**
 * @export
 */
export const QuestionByIdReadResponseQuestionAnswerTypeEnum = {
  Text: 'text',
  Select: 'select'
} as const;
export type QuestionByIdReadResponseQuestionAnswerTypeEnum =
  (typeof QuestionByIdReadResponseQuestionAnswerTypeEnum)[keyof typeof QuestionByIdReadResponseQuestionAnswerTypeEnum];

/**
 * @export
 */
export const QuestionByIdReadResponseQuestionStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type QuestionByIdReadResponseQuestionStatusEnum =
  (typeof QuestionByIdReadResponseQuestionStatusEnum)[keyof typeof QuestionByIdReadResponseQuestionStatusEnum];

/**
 * Check if a given object implements the QuestionByIdReadResponseQuestion interface.
 */
export function instanceOfQuestionByIdReadResponseQuestion(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'level' in value;
  isInstance = isInstance && 'answer_type' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'translations' in value;

  return isInstance;
}

export function QuestionByIdReadResponseQuestionFromJSON(
  json: any
): QuestionByIdReadResponseQuestion {
  return QuestionByIdReadResponseQuestionFromJSONTyped(json, false);
}

export function QuestionByIdReadResponseQuestionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): QuestionByIdReadResponseQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    name: json['name'],
    level: json['level'],
    answer_type: json['answer_type'],
    status: json['status'],
    created_at: new Date(json['created_at']),
    translations: (json['translations'] as Array<any>).map(
      QuestionByIdReadResponseQuestionTranslationsInnerFromJSON
    )
  };
}

export function QuestionByIdReadResponseQuestionToJSON(
  value?: QuestionByIdReadResponseQuestion | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    name: value.name,
    level: value.level,
    answer_type: value.answer_type,
    status: value.status,
    created_at: value.created_at.toISOString(),
    translations: (value.translations as Array<any>).map(
      QuestionByIdReadResponseQuestionTranslationsInnerToJSON
    )
  };
}
