import type { ResponseContext } from '@openapi/runtime';
import { Configuration } from '@openapi/runtime';
import { ManageApi } from '@openapi/apis/ManageApi';
import { SellApi } from '@openapi/apis/SellApi';
import { ReportingApi } from '@openapi/apis/ReportingApi';
import { directus } from './directus';
import { DIRECTUS_API_URL } from '@/config';
import router from '@/router';
import { AuthApi } from '@openapi/apis/AuthApi';
import i18n from '@/i18n';
import { ROUTE_NAMES } from '@/constants/routes';
import { LookupPartnerByUserExceptionCode } from '@/constants/exceptions';
import type { RouteLocationNormalized } from 'vue-router';
import { useLocaleStore } from '@/stores/locales.ts';

const controller = new AbortController();
const { signal } = controller;

async function postMiddleware(responseContext: ResponseContext) {
  const contentType = responseContext.response.headers.get('content-type');
  if (!contentType || !contentType.toLowerCase().includes('application/json')) {
    return;
  }

  /**
   * If an exception is thrown by the endpoint, logout the user
   * This is to cover the case when the user logs out in a different
   * tab but the current tab is still open and the auth_token still exists
   */
  const responseJson = await responseContext.response.json();
  if (
    responseJson.exception_code &&
    responseJson.exception_code === LookupPartnerByUserExceptionCode.PartnerNotFound
  ) {
    // This will throw a DOMException and all requests will be aborted
    controller.abort();
    // If the user is not logged in, logout
    await directus.logout();
    await router.push({ name: ROUTE_NAMES.LOGIN });
  }
}

export async function navigateToLoginPage(to?: RouteLocationNormalized) {
  await router.push({
    name: ROUTE_NAMES.LOGIN,
    query: {
      errorMessage: i18n.global.t('portal_session_expired_text'),
      errorSeverity: 'info',
      ...(to?.name && router.hasRoute(to?.name) ? { redirect: to.fullPath } : {})
    }
  });
}

const preMiddleware = async (context: ResponseContext) => {
  // Add the abort signal to the context
  context.init.signal = signal;

  // Try to get directus access token
  // - if getToken throws an error, navigate to login page
  // - if token is null, navigate to login page
  let directusAccessToken: string | null = null;
  try {
    directusAccessToken = await directus.getToken();
  } catch {
    await navigateToLoginPage();
  }
  if (!directusAccessToken) {
    await navigateToLoginPage();
  }
  addAuthorizationHeaderToRequest(context, directusAccessToken);
  await appendLocaleCodeToQueryParams(context);

  return context;
};

const addAuthorizationHeaderToRequest = (
  context: ResponseContext,
  directusAccessToken: string | null
) => {
  context.init.headers = {
    ...context.init.headers,
    Authorization: `Bearer ${directusAccessToken}`
  };
};

const appendLocaleCodeToQueryParams = async (
  context: ResponseContext
): Promise<Response | void> => {
  const { method } = context.init;

  const { getSelectedLocale } = useLocaleStore();

  // Add locale_code to the query params if it's a GET request or to the body if it's a POST, PUT or PATCH request
  if (method === 'GET') {
    const url = new URL(context.url);
    url.searchParams.set('locale_code', getSelectedLocale);
    context.url = url.toString();
  }
};

const config = new Configuration({
  basePath: DIRECTUS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  middleware: [
    {
      pre: preMiddleware,
      post: postMiddleware
    }
  ]
});

const sellApi = new SellApi(config);
const manageApi = new ManageApi(config);
const reportingApi = new ReportingApi(config);
const authApi = new AuthApi(config);

const unauthenticatedApiConfig = new Configuration({
  basePath: DIRECTUS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const unauthenticatedAuthApi: AuthApi = new AuthApi(unauthenticatedApiConfig);

/**
 * Expose the various Ticketing API's
 */
export { manageApi, sellApi, reportingApi, authApi, unauthenticatedAuthApi };
