/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner } from './ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner';
import {
  ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSON,
  ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSONTyped,
  ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerToJSON
} from './ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner';

/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationBulkUpsertRequest
 */
export interface ChannelProductOptionChannelConfigurationBulkUpsertRequest {
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequest
   */
  locale_code: string;
  /**
   *
   * @type {Array<ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner>}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequest
   */
  product_option_channel_configurations: Array<ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInner>;
}

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationBulkUpsertRequest interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationBulkUpsertRequest(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_option_channel_configurations' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationBulkUpsertRequest {
  return ChannelProductOptionChannelConfigurationBulkUpsertRequestFromJSONTyped(json, false);
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationBulkUpsertRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    product_option_channel_configurations: (
      json['product_option_channel_configurations'] as Array<any>
    ).map(
      ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerFromJSON
    )
  };
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestToJSON(
  value?: ChannelProductOptionChannelConfigurationBulkUpsertRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    product_option_channel_configurations: (
      value.product_option_channel_configurations as Array<any>
    ).map(
      ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerToJSON
    )
  };
}
