/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
 */
export interface ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner {
  /**
   * product id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  product_id: string;
  /**
   * product name
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  product_name: string;
  /**
   * product option id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  product_option_id: string;
  /**
   * product option name
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  product_option_name: string;
  /**
   * product option channel configuration id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  product_option_channel_configuration_id: string | null;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  channel_connection_status: ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum;
  /**
   *
   * @type {number}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  channel_commission_rate?: number | null;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  reservable_before: string | null;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner
   */
  reservable_after: string | null;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum =
  {
    Active: 'active',
    Deleted: 'deleted',
    Inactive: 'inactive',
    Draft: 'draft'
  } as const;
export type ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum =
  (typeof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum)[keyof typeof ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerChannelConnectionStatusEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_name' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'product_option_name' in value;
  isInstance = isInstance && 'product_option_channel_configuration_id' in value;
  isInstance = isInstance && 'channel_connection_status' in value;
  isInstance = isInstance && 'reservable_before' in value;
  isInstance = isInstance && 'reservable_after' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner {
  return ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    product_id: json['product_id'],
    product_name: json['product_name'],
    product_option_id: json['product_option_id'],
    product_option_name: json['product_option_name'],
    product_option_channel_configuration_id: json['product_option_channel_configuration_id'],
    channel_connection_status: json['channel_connection_status'],
    channel_commission_rate: !exists(json, 'channel_commission_rate')
      ? undefined
      : json['channel_commission_rate'],
    reservable_before: json['reservable_before'],
    reservable_after: json['reservable_after']
  };
}

export function ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerToJSON(
  value?: ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    product_id: value.product_id,
    product_name: value.product_name,
    product_option_id: value.product_option_id,
    product_option_name: value.product_option_name,
    product_option_channel_configuration_id: value.product_option_channel_configuration_id,
    channel_connection_status: value.channel_connection_status,
    channel_commission_rate: value.channel_commission_rate,
    reservable_before: value.reservable_before,
    reservable_after: value.reservable_after
  };
}
