import { Temporal } from '@js-temporal/polyfill';
import i18n from '@/i18n';
import { useLocaleStore } from '@/stores/locales.ts';

export const toTemporalDuration = (temporalString?: string) => {
  if (!temporalString) {
    return '';
  }
  const hoursAndMinutesTemporal = Temporal.Duration.from(temporalString).round({
    smallestUnit: 'minute'
  });
  return `${hoursAndMinutesTemporal.hours}h ${hoursAndMinutesTemporal.minutes}m`;
};
export const toTemporalDurationLocalizedString = (temporalString?: string | null) => {
  if (!temporalString) {
    return '';
  }
  const hoursAndMinutesTemporal = Temporal.Duration.from(temporalString).round({
    largestUnit: 'day',
    smallestUnit: 'minute'
  });
  if (hoursAndMinutesTemporal.blank) {
    return '0 minutes';
  }
  const { getSelectedLocale } = useLocaleStore();
  return hoursAndMinutesTemporal.toLocaleString(getSelectedLocale);
};

const SCHEDULE_AFTER = 1;

export const optionAfter = () => {
  return {
    id: SCHEDULE_AFTER,
    name: i18n.global.t('portal_duration_after_label')
  };
};
const SCHEDULE_BEFORE = -1;
export const optionBefore = () => {
  return {
    id: SCHEDULE_BEFORE,
    name: i18n.global.t('portal_duration_before_label')
  };
};
