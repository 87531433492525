/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner
 */
export interface ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner {
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner
   */
  channel_category_value: string | null;
}

/**
 * Check if a given object implements the ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner interface.
 */
export function instanceOfChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'channel_category_value' in value;

  return isInstance;
}

export function ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInnerFromJSON(
  json: any
): ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner {
  return ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInnerFromJSONTyped(json, false);
}

export function ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_category_id: json['pricing_category_id'],
    channel_category_value: json['channel_category_value']
  };
}

export function ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInnerToJSON(
  value?: ChannelCategoryMappingUpdateRequestPricingCategoryMappingsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_category_id: value.pricing_category_id,
    channel_category_value: value.channel_category_value
  };
}
