/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner } from './ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner';
import {
  ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSON,
  ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSONTyped,
  ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerToJSON
} from './ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner';

/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationsReadResponse
 */
export interface ChannelProductOptionChannelConfigurationsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  exception_message?: string;
  /**
   * channel id
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  channel_id: string;
  /**
   * channel name
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  channel_name: string;
  /**
   *
   * @type {Array<ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner>}
   * @memberof ChannelProductOptionChannelConfigurationsReadResponse
   */
  channel_product_option_channel_configurations: Array<ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInner>;
}

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationsReadResponse interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationsReadResponse(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'channel_id' in value;
  isInstance = isInstance && 'channel_name' in value;
  isInstance = isInstance && 'channel_product_option_channel_configurations' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationsReadResponseFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationsReadResponse {
  return ChannelProductOptionChannelConfigurationsReadResponseFromJSONTyped(json, false);
}

export function ChannelProductOptionChannelConfigurationsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    channel_id: json['channel_id'],
    channel_name: json['channel_name'],
    channel_product_option_channel_configurations: (
      json['channel_product_option_channel_configurations'] as Array<any>
    ).map(
      ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerFromJSON
    )
  };
}

export function ChannelProductOptionChannelConfigurationsReadResponseToJSON(
  value?: ChannelProductOptionChannelConfigurationsReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    channel_id: value.channel_id,
    channel_name: value.channel_name,
    channel_product_option_channel_configurations: (
      value.channel_product_option_channel_configurations as Array<any>
    ).map(
      ChannelProductOptionChannelConfigurationsReadResponseChannelProductOptionChannelConfigurationsInnerToJSON
    )
  };
}
